import styled, { css } from 'styled-components'
import Img from 'react-image'
import Select from 'react-select'

export const FocusEffect = css`
  &:focus {
    border-color: ${props => props.theme.platformColor};
    box-shadow: 0 0 0 1px ${props => props.theme.platformColor};
    outline: 0;
  }
`

export const MaxWidthWrapper = styled.div`
  max-width: ${props => props.maxWidth || 1600}px;
  width: 100%;
  margin: 0 auto;
  position: relative;
`

export const RoundedInput = styled.input`
  border-radius: 100vw;
  border: none;
  padding: 12px;
  width: 100%;
  outline: 0;
  &:focus {
    border-color: ${props => props.theme.color.primaryVeryLight};
    box-shadow: 0 0 0 1px ${props => props.theme.color.primaryVeryLight};
    outline: 0;
  }
`

export const ReportContainerWrapper = styled(MaxWidthWrapper)`
  height: 100%;
  position: relative;
  ${props => props.theme.flex.centerAll}
  justify-content: flex-start;
  padding-left: 3%;
  ${props => props.theme.media.lessThan('mid')`
    flex-direction: column-reverse;
    padding-left: 0;
    justify-content: flex-end;
    height: fit-content;
    padding-bottom: 50px;
  `}
`

export const ReportImgWrapper = styled(Img)`
  position: relative;
  max-width: 882px;
  width: 50%;
  min-width: 750px;
  height: auto;
  left: 3%;
  ${props => props.theme.media.lessThan('mid')`
    position: relative;
    left: unset;
    top: unset;
    width: 100%;
    min-width: 500px;
    max-width: 685px;
  `}
`

export const Label = styled.label`
  font-size: 11px;
  line-height: 17px;
  font-family: ${props => props.theme.fontFamilyInter};
  text-transform: uppercase;
  letter-spacing: 1.65px;
  color: #888888;
`

export const RanksWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-itms: center;
  justify-content: center;
`

export const TitleWrapper = styled.div`
  font-weight: bold;
  font-size: 40px;
  line-height: 40px;
  width: 90%;
  max-width: 700px;
  margin: 40px auto;
  text-align: center;
  ${props => props.theme.media.lessThan('mobile')`
    font-size: 34px;
  `}
`
export const DescriptionWrapper = styled.div`
  font-size: 16px;
  width: 90%;
  max-width: 800px;
  margin: 0 auto;
  ${props => props.theme.media.lessThan('1250px')`
    max-width: 600px;
  `}
  ${props => props.theme.media.lessThan('600px')`
    width: 85%;
  `}
`

export const SearchLabel = styled.label`
  font-size: 11px;
  line-height: 17px;
  font-family: ${props => props.theme.fontFamilyInter};
  text-transform: uppercase;
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 1.65px;
  color: #888888;
  padding-left: 12px;
  position: relative;
`

export const RoundedSelect = styled(Select)`
  .creatable-multi-select__control {
    border-radius: 7px;
    font-size: 14px;
  }

  .creatable-multi-select__control--is-focused {
    border-color: ${props => props.theme.platformColorLight} !important;
    box-shadow: 0 0 0 1px ${props => props.theme.platformColor} !important;
  }

  .creatable-multi-select__value-container {
    padding-left: 12px;
  }

  .creatable-multi-select__menu {
    z-index: 1000;
    font-size: 14px;

    .creatable-multi-select__option--is-focused {
      background-color: ${props => props.theme.platformColorLight};
      color: #ffffff;
    }
    .creatable-multi-select__option--is-selected {
      background-color: ${props => props.theme.platformColor};
      color: #ffffff;
    }

    .creatable-multi-select__multi-value__label {
    }
  }
  .creatable-multi-select__placeholder {
    font-size: ${props => props.placeholderFontSize || 16}px;
  }
`

export const Container = styled.div`
  width: 100%;
  max-width: 1350px;
  margin: 0 auto;
  padding: 20px;
  position: relative;

  ${props => props.theme.media.lessThan('tablet')`
    padding: 10px;
  `}
`;
